import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { getSvgURL, IconCommon } from 'lib';
import { useTheme } from 'contexts/ThemeContext';
import { ROUTES } from 'constants/routes';
import { getTeacherQuestionStatusAction } from 'store/childTeacherDetailQuestion/actions';

import { SidebarProps, SidebarTypes } from 'components/LayoutBase/components/Sidebar/types';
import { Navigation } from 'components/LayoutBase/components/Sidebar/Navigation';

import {
  isAdminOrBranchManagerRoleSelector,
  isB2bStudentRoleSelector,
  schoolLogoSelector,
} from 'store/account/selectors';
import { SubMenus } from './SubMenus';
import { Menu } from './Menu';
import styles from './Sidebar.module.scss';

export function Sidebar({
  sidebarType,
  menuItems,
  isOpen,
  setIsOpen,
  showHamburger,
}: Omit<SidebarProps, 'profileDetails'>) {
  const { theme } = useTheme();
  const { pathname } = useLocation();
  const elRef = useRef<{ [key: string]: HTMLAnchorElement | null }>({});

  const isAdminOrBM = useSelector(isAdminOrBranchManagerRoleSelector);
  const isB2bStudent = useSelector(isB2bStudentRoleSelector);
  const schoolLogo = useSelector(schoolLogoSelector);

  const isALW = sidebarType === SidebarTypes.ALW;

  useEffect(() => {
    const activeElementRef = elRef.current[pathname];
    activeElementRef?.scrollIntoView({ block: 'nearest' });
  }, [pathname]);

  return (
    <aside
      className={cn(styles.sidenav, {
        [styles.hamburger]: showHamburger,
        [styles.open]: isOpen,
      })}
    >
      <span className={styles.crossicon}>
        <IconCommon className="icon-cancel" clickHandler={setIsOpen} />
      </span>
      <NavLink to={ROUTES.root} className={styles.sidenav__logo} onClick={setIsOpen}>
        <img
          src={
            isB2bStudent && schoolLogo
              ? schoolLogo
              : getSvgURL(theme === 'dark' ? 'dark-logo' : 'logo')
          }
          alt="ALW"
        />
      </NavLink>
      {!isALW && isAdminOrBM && (
        <Navigation isHamburger={showHamburger} onBranchSwitch={setIsOpen} />
      )}
      <nav className={cn(styles.navigation)}>
        {menuItems?.map(({ id, path, ...props }) => {
          const menuProps = {
            key: id,
            id,
            path,
            setIsOpen,
            ...props,
            setElement: (el: HTMLAnchorElement | null) => {
              elRef.current[path] = el;
            },
          };
          return <>{props.subMenus ? <SubMenus {...menuProps} /> : <Menu {...menuProps} />}</>;
        })}
      </nav>
    </aside>
  );
}

export default Sidebar;
